:root {
  --rcs-ellipsis-color: #fff;
  --rcs-ellipsis-size: 64px;
}

.rcs-ellipsis {
  display: inline-block;
  position: relative;
  width: var(--rcs-ellipsis-size);
  height: var(--rcs-ellipsis-size);
}

.rcs-ellipsis div {
  position: absolute;
  top: calc(var(--rcs-ellipsis-size) / 2.42424);
  width: calc(var(--rcs-ellipsis-size) / 6.15385);
  height: calc(var(--rcs-ellipsis-size) / 6.15385);
  border-radius: 50%;
  background: var(--rcs-ellipsis-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.rcs-ellipsis div:nth-child(1) {
  left: calc(var(--rcs-ellipsis-size) / 10);
  animation: rcs-ellipsis1 0.6s infinite;
}

.rcs-ellipsis div:nth-child(2) {
  left: calc(var(--rcs-ellipsis-size) / 10);
  animation: rcs-ellipsis2 0.6s infinite;
}

.rcs-ellipsis div:nth-child(3) {
  left: calc(var(--rcs-ellipsis-size) / 2.5);
  animation: rcs-ellipsis2 0.6s infinite;
}

.rcs-ellipsis div:nth-child(4) {
  left: calc(var(--rcs-ellipsis-size) / 1.42857);
  animation: rcs-ellipsis3 0.6s infinite;
}

@keyframes rcs-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rcs-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes rcs-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(var(--rcs-ellipsis-size) / 3.33333), 0);
  }
}
